<template>
  <div ref="sky" class="sky" />
</template>

<script>
export default {
  name: 'Sky',
  computed: {
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    levelMap() {
      return this.$store.state.game.gameInfoMap.levels[this.gameInfo.current_level]
      // if (this.gameInfo.current_level !== undefined) return this.$store.state.game.gameInfoMap.levels[this.gameInfo.current_level]
      // else return this.$store.state.game.gameInfoMap.levels.idle
    },
    gameInfoMap() {
      return this.$store.state.game.gameInfoMap.levels[this.level]
    },
  },
  watch: {
    levelMap: {
      immediate: true,
      handler(to, from) {
        if (to && from && to.images.sky !== from.images.sky || to && from === undefined)
        this.$nextTick(async () => {
          this.$tween.to(this.$refs.sky, 0.5, { opacity: '0' })
          this.$tween.to(this.$refs.sky, 2, { opacity: '1', backgroundImage: `url(/images/${to.images.sky}.png)` });
        });
      },
    },
  },
  mounted () {
    this.$log('mounted')
    this.$nextTick(async () => {
      // Sky controls
      this.$tween.to(this.$refs.sky, 2, { opacity: '1', backgroundImage: `url(/images/${this.levelMap.images.sky}.png)` })
    })
  },
}
</script>

<style lang="scss" scoped>
.sky {
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: calc(
    1500px + (2000 - 1500) * ((100vw - 900px) / (1600 - 900))
  );
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .sky {
    background-size: 1000px;
  }
}
</style>