<template>
  <div class="flex items-center content-center justify-center h-screen w-scren">
    <Dialog
      headerText="Как будешь играть?"
      :footerShow="false"
      :style="{maxWidth: '500px'}"
    >
      <div class="flex flex-wrap items-center content-center w-full py-6">
        <button
          class="geecko-button"
          type="submit"
          @click="musicSet(true)"
          :style="{
            background: '#FFEF40',
          }"
        >
          C музыкой
        </button>
        <button
          class="geecko-button--outline ml-4"
          type="submit"
          @click="musicSet(false)"
        >
          Без музыки
        </button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Game/Dialog.vue';

export default {
  name: 'DialogMusic',
  components: {Dialog},
  methods: {
    async musicSet (val) {
      this.$log('musicSet', val)
      this.$store.commit("ui/SET_STATE_PROP", ["soundOn", val])
      this.$emit("finished")
    },
  }
}
</script>

<style lang="scss" scoped>
</style>