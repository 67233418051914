<template>
  <div class="dialog-container">
    <div ref="dialog" class="dialog" :style="{ top: dialogTopValue }">
      <!-- header -->
      <div class="dialog-head">
        <div
          class="dialog-head__img"
          :style="{ backgroundImage: `url(/images/sber-logo.svg)`, backgroundPosition: 'center' }"
        />
      </div>
      <!-- body -->
      <div ref="cont" class="dialog-content">
        <h2 style="font-size: 24px; color: #fff">
          Хочешь работать в Сбере?
        </h2>
        <!-- footer -->
        <div
          ref="content"
          class="flex flex-row flex-wrap w-full buttons"
        >
          <button class="geecko-button" @click="yes()">
            хочу в сбер
          </button>
          <button class="geecko-button--outline" @click="no()">
            не хочу
          </button>
          <button class="geecko-button--ghost" @click="already()">
            уже работаю
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    headerImage: { type: String, default: '/images/game/sber-logo.png' },
  },
  data() {
    return {
      dialogTop: 0,
      headerText: 'Хочешь работать в сбере?',
    };
  },
  computed: {
    dialogTopValue() {
      return window.innerWidth > 768 && !!this.dialogTop
        ? `calc(50% - ${this.dialogTop}px / 2)`
        : 'auto';
    },
  },
  async mounted() {
    this.$nextTick(async () => {
      this.dialogTop = this.$refs.dialog.offsetHeight;
      const dia = this.$refs.dialog;
      const anim = new this.$timeline();
      const cont = this.$refs.cont;
      const content = this.$refs.content;
      anim.set(dia, { width: '0%', height: '48px' }).set(cont, { height: '0%', padding: '0 32px', overflow: 'hidden' }).set(content, {opacity: '0'});
      // anim.set(cont, { opacity: '0' });
      anim.to(dia, 0.6, { width: '100%' });
      // anim.to(cont, 1, { opacity: '1' });
      await this.$wait(200);
      anim.to(dia, 0.2, { height: 'auto' }).to(cont, 0.2, { height: '100%', padding: '24px 32px' });
      await this.$wait(1000);
      anim.to(content, 0.2, { opacity: '1' });
    })
  },
  methods: {
    async yes() {
      console.log('yes');
      this.$emit('finished');
      await this.$http.post('/user/set-want-offer', { want_offer: 'yes' });
    },
    async no() {
      console.log('no');
      this.$emit('finished');
      await this.$http.post('/user/set-want-offer', { want_offer: 'no' });
    },
    async already() {
      console.log('already');
      this.$emit('finished');
      await this.$http.post('/user/set-want-offer', { want_offer: 'already' });
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  max-width: 400px;
}
.dialog-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  // border: 1px solid red;
}
.dialog {
  max-width: 700px;
  // height: auto;
  &-head {
    color: #e0e0e0;
    background: #2e2c3f;
    position: relative;
    padding-left: 115px;
    --height: 48px;
    height: var(--height);
    --clip-size: 16px;
    clip-path: polygon(
      0 0,
      0 -29px,
      calc(100% - var(--clip-size)) 0,
      100% var(--clip-size),
      100% 100%,
      0 100%
    );
    &__img {
      position: absolute;
      left: 23px;
      bottom: 0;
      --size: 130px;
      height: 48px;
      width: var(--size);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    &__name {
      font-size: 17px;
      line-height: 100%;
      font-family: 'BlenderPro Bold';
      line-height: var(--height);
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
  &-content {
    background: #201e2e;
    padding: 24px 32px 32px 32px;
    height: auto;
  }
}
.dialog-text {
  color: #e0e0e0;
  display: inline-block;
}

@media (max-width: 768px) {
  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    button {
      margin-top: 20px;
      max-width: 400px;
      width: 100%;
    }
  }
  .dialog {
    height: 100% !important;
    max-width: 100%;
    &-content {
      height: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
</style>
