<template>
  <div
    ref="clouds"
    class="clouds"
  />
</template>

<script>
import { Power0 } from 'gsap';

export default {
  name: 'Clouds',
  data() {
    return {
    }
  },
  mounted () {
    this.$log('mounted')
    this.$nextTick(() => {
      const clouds = new this.$timeline({ repeat: -1 }).timeScale(1);
      clouds.to(this.$refs.clouds, 50, { backgroundPositionX: '-5000px', ease: Power0.easeNone });
    })
  },
};
</script>

<style lang="scss" scoped>
.clouds {
  background-image: url('/images/clouds.png');
  opacity: 0.5;
  height: 520px;
  max-height: calc(100vh - 250px);
  width: 100%;
  background-size: 5000px;
  position: absolute;
  top: 250px;
  left: 0;
  background-position-y: -60px;
  background-repeat: repeat;
  z-index: 2;
}
@media screen and (max-width: 768px) {
  .clouds {
    background-size: 2000px;
    top: 0;
    height: 200px;
    z-index: 3;
  }
}
</style>
