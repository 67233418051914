<template>
  <div class="flex items-center content-center justify-center h-screen w-scren">
    <Dialog
      headerText="Таблица лидеров"
      :footerShow="false"
      :style="{maxWidth: '500px'}"
    >
      <div class="flex flex-wrap items-center content-center w-full py-6">
        <p class="text-white">
          В турнирной таблице учитывается только первое прохождение.
          Все последующие результаты не попадут в турнирную таблицу.
        </p>
        <!-- <p style="color: #40FF90; margin-top: 10px;">
          Нужен твой телефон
        </p> -->
        <p v-if="!user.phone" class="mt-4 text-white">
          Для вручения призов нам может понадобиться твой телефон.
        </p>
        <form
          v-if="!user.phone"
          @submit.prevent="handleSubmit()">
          <div class="w-full">
            <input
              v-model="phone"
              v-mask="'+# (###) ###-##-##'"
              id="phone"
              class="phone-input w-full"
              type="phone"
              placeholder="+7"
            />
          </div>
          <button
            class="geecko-button mt-6"
            type="submit"
            :disabled="!canStartGame"
            :style="{
              background: canStartGame ? '#FFEF40' : '#7F7E8A'
            }"
          >
            Начать игру
          </button>
        </form>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Game/Dialog.vue';

export default {
  name: 'DialogInfo',
  components: {Dialog},
  data () {
    return {
      phone: ''
    }
  },
  computed: {
    user() {
      return this.$store.getters["user/user"]
    },
    canStartGame () {
      if (this.user.phone) {
        return true
      }
      else {
        return this.phone.length >= 11
      }
    }
  },
  methods: {
    async handleSubmit () {
      this.$log('handleSubmit')
      if (this.phone.length > 3) {
        let phone = this.phone.replace(/[^0-9]/g, '')
        await this.$http.post('/user/set-phone', { phone: '+' + phone })
        this.$emit('finished')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.phone-input {
  max-width: 240px;
  width: 100%;
  background: #2E2C3F;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
}
</style>
