<template>
  <div
    ref="dialog"
    class="flex flex-row flex-wrap dialog"
    :style="{
      maxWidth: '700px',
      maxHeight: '500px',
      marginLeft: $store.state.ui.width >= 1200 ? '-200px' : '',
      overflow: 'visible',
    }"
  >
    <!-- header -->
    <div
      class="flex flex-row flex-wrap w-full"
      :style="{
        position: 'relative',
        paddingBottom: '0px',
      }"
    >
      <!-- question.image -->
      <div
        v-if="question.image && $store.state.ui.width >= 1200"
        class=""
        :style="{
          position: 'absolute', zIndex: 400, top: '0px', right: '-20px', overflow: 'visible', width: '0px',
        }"
      >
        <div
          class="flex flex-row flex-wrap items-start content-start"
          :style="{
            width: '500px',
          }"
        >
          <img
            draggable="false"
            class="boss-image"
            :src="imageUrl"
            :style="{
              zIndex: 400,
              maxHeight: '400px',
              width: '600px',
              pointerEvents: 'none',
              userSelect: 'none',
              opacity: 1,
            }"
          />
        </div>
      </div>
      <!-- question.title -->
      <p
        :style="{
          fontSize: '24px',
          fontWeight: 'bold',
          color: 'white',
          paddingBottom: '10px',
        }"
      >
        {{ question.question }}
      </p>
    </div>
    <!-- image mobile -->
    <div
      v-if="question.image && $store.state.ui.width < 1200"
      class="flex flex-row flex-wrap w-full items-start content-start"
    >
      <img
        draggable="false"
        class="boss-image"
        :src="imageUrl"
        :style="{
          zIndex: 10,
          height: $store.state.ui.width > 768 ? '300px' : '200px',
          maxWidth: '100%',
          pointerEvents: 'none',
          userSelect: 'none',
          objectFit: 'contain',
        }"
      />
    </div>
    <!-- Answer options -->
    <div
      class="flex flex-row flex-wrap w-full"
    >
      <button
        v-for="answer in question.answers"
        :key="answer.id"
        style="max-width: 500px; width: 100%;"
        class="geecko-button--secondary quiz__answer"
        :disabled="answerStatus !== null"
        :class="{
          selected: answerSelectedId === answer.id && answerStatus === null,
          right: answerSelectedId === answer.id && answerStatus === 'right',
          wrong: answerSelectedId === answer.id && answerStatus === 'wrong'
        }"
        :style="{
          height: 'auto !important',
        }"
        @click="answerStatus === null ? answerSelectedId = answer.id : null"
      >
        {{ answer.text }}
      </button>
    </div>
    <!-- answer btn -->
    <div
      class="flex flex-row flex-wrap w-full"
      :style="{
        paddingTop: '10px',
        minHeight: '60px',
      }"
    >
      <!-- Answer confirm -->
      <button
        v-if="answerSelectedId && answerStatus === null"
        class="geecko-button"
        :disabled="answerSent"
        :style="{
          background: !answerSent ? '#FFEF40' : '#7F7E8A'
        }"
        @click="answerSent = true, $emit('answer', answerSelectedId)"
      >
        {{ answerSent && !answerExplained ? 'Загрузка...' : 'Ответить' }}
      </button>
      <!-- Answer status actions -->
      <div
        v-if="answerExplained && answerStatus !== null"
        class="flex flex-row flex-wrap w-full justify-between">
        <button
          
          class="geecko-button"
          @click="$emit('next')"
        >
          Следующий вопрос
        </button>
        <button
          v-if="hasExplain"
          class="geecko-button--outline"
          @click="$emit('explain')"
        >
          ?
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuizBoss',
  props: {
    question: {
      type: Object,
      required: true,
    },
    hasExplain: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      answerSelectedId: null,
      answerSent: false,
      answerStatus: null, // right, wrong
      answerExplained: false,
    }
  },
  computed: {
    imageUrl () {
      if (this.$store.getters.isLocal) {
        // return process.env.VUE_APP_SERVER_URL + url;
        return '/images/banner.png'
      }
      else {
        return process.env.VUE_APP_SERVER_URL + this.question.image
      }
    }
  },
  watch: {
    question: {
      deep: true,
      handler (to) {
        this.$log('W question TO', to)
        if (to) {
          this.$log('W question REFRESH')
          this.answerSent = false
          this.answerSelectedId = null
          this.answerStatus = null
          this.answerExplained = false
        }
      },
    },
  },
  mounted() {
    this.$log('mounted')
    this.$nextTick(() => {
      const dialog = this.$refs.dialog;
      this.$tween.set(dialog, { opacity: '0' });
      this.$tween.to(dialog, 1, { opacity: '1' });
    })
  },
};
</script>

<style lang="scss" scoped>
.boss-image {
  border: 3px solid rgba(109, 237, 252, 0.5);
  box-shadow: 0px 0px 26px 5px rgba(109, 237, 252, 0.25)
}
@media screen and (max-width: 768px) {
  .dialog {
    left: 0;
    top: 41vh !important;
    max-width: 100vw !important;
    width: 100% !important;
  }
}
.quiz__answer {
  display: block;
  // position: relative;
  margin: 4px 0;
  max-width: 700px !important;
  width: 100% !important;
  position: relative;
  padding-right: 64px;
  padding-bottom: 10px;
  padding-top: 10px;
  &.selected {
    --border-color: rgb(255,240,64);
    color: white;
    border: 1px solid rgb(255,240,64);
    box-shadow: inset -4px 4px 16px rgba(255,240,64,0.25),
      inset 0px -4px 16px rgba(255,240,64,0.25),
      inset 4px 0px 16px rgba(255,240,64,0.25),
      inset 0px 4px 16px rgba(255,240,64,0.25);
  }
  &.right {
    --border-color: rgb(86, 174, 23);
    color: white;
    border: 1px solid rgb(86, 174, 23);
    box-shadow: inset -4px 4px 16px rgba(86, 174, 23,0.25),
      inset 0px -4px 16px rgba(86, 174, 23,0.25),
      inset 4px 0px 16px rgba(86, 174, 23,0.25),
      inset 0px 4px 16px rgba(86, 174, 23,0.25);
  }
  &.wrong {
    --border-color: rgb(255, 83, 64);
    color: white;
    border: 1px solid rgb(255, 83, 64);
    box-shadow: inset -4px 4px 16px rgba(255, 83, 64,0.25),
      inset 0px -4px 16px rgba(255, 83, 64,0.25),
      inset 4px 0px 16px rgba(255, 83, 64,0.25),
      inset 0px 4px 16px rgba(255, 83, 64,0.25);
  }
}
</style>
