var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row flex-wrap w-full justify-center",style:({
    position: 'relative',
    overflowX: 'hidden',
  })},[_vm._l((_vm.images),function(f,fkey){return _c('div',{key:fkey},[_c('div',{style:({position: 'absolute', backgroundImage: ("url(/images/" + f + ".png)")})})])}),_c('div',{staticClass:"flex flex-row flex-wrap w-full justify-center",style:({
      position: 'absolute',
      zIndex: 10000,
      top: '0px',
    })},[(true)?_c('GameHeader'):_vm._e()],1),(_vm.stage === 'dialog_music')?_c('DialogMusic',{style:({
      position: 'absolute',
      zIndex: 10000,
    }),on:{"finished":function($event){_vm.user.phone ? _vm.dialogInfoFinished() : _vm.stage = 'dialog_info'}}}):_vm._e(),(_vm.stage === 'dialog_info')?_c('DialogInfo',{style:({
      position: 'absolute',
      zIndex: 10000,
    }),on:{"finished":function($event){return _vm.dialogInfoFinished()}}}):_vm._e(),(_vm.bossStats)?_c('DialogBossEnd',{style:({
      position: 'absolute',
      zIndex: 1000,
      top: '0px',
      width: '100%',
      height: '100%',
    }),on:{"finished":function($event){(_vm.bossStats = false), _vm.sberOffer()}}}):_vm._e(),_c('div',{staticClass:"flex flex-row flex-wrap w-full justify-center",style:({
      position: 'relative',
      height: _vm.$store.state.ui.width > 768 ? '100vh' : '35vh',
    })},[(true)?_c('Sky'):_vm._e(),(true)?_c('Clouds',{style:({
        position: 'absolute',
        bottom: 0,
        zIndex: 9,
        pointerEvents: 'none',
      })}):_vm._e(),(true)?_c('City',{style:({
        position: 'absolute',
        bottom: 0,
        zIndex: 10,
        pointerEvents: 'none',
      })}):_vm._e(),(true)?_c('Ground',{style:({
        position: 'absolute',
        bottom: 0,
        zIndex: 100,
        pointerEvents: 'none',
      })}):_vm._e(),(['c_1', 'c_2', 'c_3'].includes(_vm.gameInfo.current_level) && _vm.$store.state.ui.width > 768)?_c('div',{ref:"tint",staticClass:"blur"}):_vm._e(),_c('div',{staticClass:"flex flex-row flex-wrap w-full justify-center",style:({
        position: 'relative',
        maxWidth: 1280 + 'px',
        height: '100%',
      })},[(true)?_c('Hero',{ref:"hero",style:({
          position: 'absolute',
          zIndex: 200,
          bottom: '65px',
          left: '0px',
          pointerEvents: 'none !important',
        })}):_vm._e(),(['c_1', 'c_2', 'c_3'].includes(_vm.gameInfo.current_level))?_c('Boss',{ref:"boss",style:({
          position: 'absolute',
          zIndex: 201,
          bottom: '65px',
          right: '0px',
          pointerEvents: 'none !important',
        }),attrs:{"boss-id":_vm.gameInfo.current_level}}):_vm._e(),(true)?_c('Npc',{ref:"npc",style:({
          pointerEvents: 'none !important',
        })}):_vm._e(),(true)?_c('div',{staticClass:"flex flex-row flex-wrap w-full",style:({
          position: 'relative',
          zIndex: 101,
          maxWidth: 700 + 'px',
          backgrounds: 'hsla(120,20%,50%,0.5)',
          marginTop:
          _vm.$store.state.ui.width > 768 ? '20vh' : '30vh',
          maxHeight: _vm.$store.state.ui.width > 768 ? '300px' : '70vh',
          padding: _vm.$store.state.ui.width > 768 ? '0px' : '10px',
          marginBottom: '200px',
          overflow: _vm.$store.state.ui.width > 768 ? 'visible' : 'auto',
          paddingTop: '30px'
        })},[(_vm.offer)?_c('DialogOffer',{style:({
            maxWidth:  _vm.$store.state.ui.width > 768 ? '600px' : 'max-content',
          }),on:{"finished":function($event){_vm.nextLevel(), _vm.offer = false}}}):_vm._e(),(_vm.stage === 'dialog_npc')?_c('DialogFlow',{ref:"dialog",style:({
            maxWidth:  _vm.$store.state.ui.width > 768 ? '600px' : '70vh',
          }),attrs:{"dialogs":_vm.levelCurrent.messagesIntro},on:{"finished":function($event){return _vm.fightBoss()}}}):_vm._e(),(_vm.stage === 'afterLastBoss')?_c('DialogFlow',{ref:"dialog",style:({
            maxWidth:  _vm.$store.state.ui.width > 768 ? '600px' : '70vh',
          }),attrs:{"dialogs":_vm.levelCurrent.messageOnWinAfter},on:{"finished":function($event){return _vm.nextLevel()}}}):_vm._e(),(_vm.stage === 'fight' && ['c_1', 'c_2', 'c_3'].includes(_vm.gameInfo.current_level))?_c('QuizBossFlow',{attrs:{"refsParent":_vm.$refs},on:{"finished":_vm.quizBossFlowFinished}}):_vm._e()],1):_vm._e()],1)],1),(_vm.$store.state.ui.width <= 768)?_c('div',{staticClass:"flex flex-row flex-wrap w-full",style:({
      position: 'relative',
      zIndex: 1000,
      minHeight: '65vh',
      background: '19192F',
      pointerEvents: 'none',
      marginBottoms: '0px',
      overflow: 'auto',
    })}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }