<template>
  <div
    class="flex flex-row flex-wrap w-full"
  >
    <!-- v-if="question && userSpec && userSpec === 'non-code' && stage === 'fight'" -->
    <QuizBoss
      v-if="question && question.type === 'quiz' && stage === 'fight'"
      ref="quiz"
      :question="question.data"
      :style="{
      }"
      :hasExplain="!!questionExplain"
      @answer="answerQuestion"
      @next="nextQuestion"
      @explain="explainQuestion"
    />
    <!-- <QuestionSnippet
      v-if="question && question.type === 'code' && stage === 'fight'"
      ref="snippet"
      :question="question.data"
      @answer="answerQuestion"
      @next="nextQuestion"
    /> -->
  </div>
</template>

<script>
import QuizBoss from '@/components/Game/QuizBoss.vue'
// import QuestionSnippet from "@/components/Game/QuestionSnippet.vue"

export default {
  name: 'QuizBossFlow',
  props: ['refsParent'],
  components: {
    QuizBoss,
    // QuestionSnippet,
  },
  data () {
    return {
      stage: 'idle', // idle, fight, win, lose
      question: null,
      questionExplain: null,
      answerQuestionWorking: false,
      nextQuestionWorking: false,
    }
  },
  computed: {
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    levelMap() {
      return this.$store.state.game.gameInfoMap.levels[this.gameInfo.current_level];
    },
    userSpec() {
      return this.$store.getters.user.specialization_id;
    },
  },
  watch: {
    gameInfo: {
      immediate: true,
      async handler (to, from) {
        this.$log('W gameInfo', to, from)
        if (to && !from) {
          const question = await this.getQuestion()
          this.$log('W gameInfo question', question)
          if (question && !question.error) {
            this.question = question
            this.stage = 'fight'
          }
        }
      }
    },
    question: (val) => {
      this.questionExplain = null
    }
  },
  methods: {
    async tryEaster () {
      this.$log('tryEaster start')
      if (this.question && this.question.data.easter) {
        this.refsParent.npc.animAppear(this.question.data.easter, 3000, 'positive');
        await this.$wait(3000)
        this.$log('tryEaster done')
      }
    },
    explainQuestion () {
      this.$log('explainQuestion')
      this.refsParent.boss.say(this.questionExplain, 4000)
    },
    async answerQuestion(id) {
      this.$log('answerQuestion', id);
      if (this.answerQuestionWorking) return;
      this.answerQuestionWorking = true;
      this.nextQuestionWorking = true
      // get result
      const { data: { result, response_text } } = await this.$http.post('/game/answer-boss-question', {
        level: this.gameInfo.current_level,
        question: this.question.data.id,
        answer: id,
      })
      this.$log('handleSendAnswer BOSS', result, response_text)
      this.questionExplain = response_text
      this.refsParent.boss.say(this.questionExplain, 4000)

      // Switch correct flow
      const answerIsCorrect = result;
      if (answerIsCorrect) {
        // Update quiz
        if (this.$refs.quiz) {
          this.$refs.quiz.answerStatus = 'right'
        }
        // Animations
        this.refsParent.hero.animAttack()
        await this.$wait(1000)
        this.refsParent.boss.animDamaged()
        // On first damage TO boss, Before Damage to Boss his health is 3
        // if (this.gameInfo.boss_health === 3) {
        //   this.refsParent.boss.say(this.levelMap.messagesOnFirstHit[0].message, 4000);
        //   await this.$wait(2000)
        //   this.refsParent.npc.animAppear(this.levelMap.messagesOnFirstHit[1].message, 3000, 'positive');
        //   await this.$wait(2000)
        //   await this.tryEaster()
        // }
        // else {
        //   await this.tryEaster()
        // }
      }
      else {
        // Update quiz
        if (this.$refs.quiz) {
          this.$refs.quiz.answerStatus = 'wrong'
        }
        // Animations
        this.refsParent.boss.animAttack()
        await this.$wait(1000)
        this.refsParent.hero.animDamaged()
        // On first damage FROM boss, befor damage from Boss, hero health is 3
        // if (this.levelMap.player_health === 3) {
        //   this.refsParent.boss.say(this.levelMap.messagesOnFirstDamage[0].message, 4000);
        //   await this.$wait(2000)
        //   this.refsParent.npc.animAppear(this.levelMap.messagesOnFirstDamage[1].message, 3000, 'wrong');
        //   await this.$wait(2000)
        //   await this.tryEaster()
        // }
        // else {
        //   await this.tryEaster()
        // }
      }
      // Update game state, this will change hit points...
      await this.$store.dispatch('game/fetchGame')
      // Next question
      await this.$wait(3000)
      // await this.nextQuestion()
      // Done
      if (this.$refs.quiz) {
        this.$refs.quiz.answerExplained = true
      }
      this.answerQuestionWorking = false
      this.nextQuestionWorking = false
    },
    async nextQuestion() {
      this.$log('nextQuestion')
      if (this.nextQuestionWorking) {
        // alert('Не спеши...')
        return
      }
      this.nextQuestionWorking = true
      const question = await this.getQuestion()
      if (question && !question.error) {
        this.$log('nextQuestion NEXT')
        this.question = question
      }
      else {
        this.$log('nextQuestion DONE', this.gameInfo)
        // TODO win or lose?
        this.stage = 'win'
        this.$emit('finished')
      }
      // check win
      // if (this.gameInfo.boss_health === 0) {
      //   this.stage = 'win'
      //   this.refsParent.boss.say(this.levelMap.messagesOnWin[0].message, 4000)
      //   await this.$wait(2000)
      //   this.refsParent.npc.animAppear(this.levelMap.messagesOnWin[1].message, 3000, 'emotion')
      //   this.refsParent.boss.animDeath()
      //   await this.$wait(5000)
      //   this.$emit('finished')
      // }
      // // check lose
      // else if (this.gameInfo.player_health === 0) {
      //   this.stage = 'lose'
      //   this.refsParent.boss.say(this.levelMap.messagesOnLose[0].message, 3000)
      //   await this.$wait(3000)
      //   this.refsParent.npc.animAppear(this.levelMap.messagesOnLose[1].message, 3000, 'emotion')
      //   this.refsParent.hero.animDeath()
      //   await this.$wait(5000)
      //   this.$emit('finished')
      // }
      // else {
      //   // update quiz
      //   if (this.$refs.quiz) {
      //     this.$refs.quiz.result = null
      //   }
      //   this.question = await this.getQuestion()
      // }
      await this.$wait(4000)
      this.nextQuestionWorking = false
    },
    async getQuestion() {
      this.$log('getQuestion');
      const { data: question } = await this.$http.get('/game/new-boss-question', {
        params: {
          try: this.gameInfo.try,
          level: this.gameInfo.current_level,
        },
      });
      return question;
    },
  },
  async mounted () {
    this.$log('mounted')
  },
  beforeDestroy () {
    this.$log('beforeDestroy')
  }
}
</script>
