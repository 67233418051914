var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"dialog",staticClass:"flex flex-row flex-wrap dialog",style:({
    maxWidth: '700px',
    maxHeight: '500px',
    marginLeft: _vm.$store.state.ui.width >= 1200 ? '-200px' : '',
    overflow: 'visible',
  })},[_c('div',{staticClass:"flex flex-row flex-wrap w-full",style:({
      position: 'relative',
      paddingBottom: '0px',
    })},[(_vm.question.image && _vm.$store.state.ui.width >= 1200)?_c('div',{style:({
        position: 'absolute', zIndex: 400, top: '0px', right: '-20px', overflow: 'visible', width: '0px',
      })},[_c('div',{staticClass:"flex flex-row flex-wrap items-start content-start",style:({
          width: '500px',
        })},[_c('img',{staticClass:"boss-image",style:({
            zIndex: 400,
            maxHeight: '400px',
            width: '600px',
            pointerEvents: 'none',
            userSelect: 'none',
            opacity: 1,
          }),attrs:{"draggable":"false","src":_vm.imageUrl}})])]):_vm._e(),_c('p',{style:({
        fontSize: '24px',
        fontWeight: 'bold',
        color: 'white',
        paddingBottom: '10px',
      })},[_vm._v(" "+_vm._s(_vm.question.question)+" ")])]),(_vm.question.image && _vm.$store.state.ui.width < 1200)?_c('div',{staticClass:"flex flex-row flex-wrap w-full items-start content-start"},[_c('img',{staticClass:"boss-image",style:({
        zIndex: 10,
        height: _vm.$store.state.ui.width > 768 ? '300px' : '200px',
        maxWidth: '100%',
        pointerEvents: 'none',
        userSelect: 'none',
        objectFit: 'contain',
      }),attrs:{"draggable":"false","src":_vm.imageUrl}})]):_vm._e(),_c('div',{staticClass:"flex flex-row flex-wrap w-full"},_vm._l((_vm.question.answers),function(answer){return _c('button',{key:answer.id,staticClass:"geecko-button--secondary quiz__answer",class:{
        selected: _vm.answerSelectedId === answer.id && _vm.answerStatus === null,
        right: _vm.answerSelectedId === answer.id && _vm.answerStatus === 'right',
        wrong: _vm.answerSelectedId === answer.id && _vm.answerStatus === 'wrong'
      },staticStyle:{"max-width":"500px","width":"100%"},style:({
        height: 'auto !important',
      }),attrs:{"disabled":_vm.answerStatus !== null},on:{"click":function($event){_vm.answerStatus === null ? _vm.answerSelectedId = answer.id : null}}},[_vm._v(" "+_vm._s(answer.text)+" ")])}),0),_c('div',{staticClass:"flex flex-row flex-wrap w-full",style:({
      paddingTop: '10px',
      minHeight: '60px',
    })},[(_vm.answerSelectedId && _vm.answerStatus === null)?_c('button',{staticClass:"geecko-button",style:({
        background: !_vm.answerSent ? '#FFEF40' : '#7F7E8A'
      }),attrs:{"disabled":_vm.answerSent},on:{"click":function($event){_vm.answerSent = true, _vm.$emit('answer', _vm.answerSelectedId)}}},[_vm._v(" "+_vm._s(_vm.answerSent && !_vm.answerExplained ? 'Загрузка...' : 'Ответить')+" ")]):_vm._e(),(_vm.answerExplained && _vm.answerStatus !== null)?_c('div',{staticClass:"flex flex-row flex-wrap w-full justify-between"},[_c('button',{staticClass:"geecko-button",on:{"click":function($event){return _vm.$emit('next')}}},[_vm._v(" Следующий вопрос ")]),(_vm.hasExplain)?_c('button',{staticClass:"geecko-button--outline",on:{"click":function($event){return _vm.$emit('explain')}}},[_vm._v(" ? ")]):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }