<template>
  <div class="ground"/>
</template>

<script>
export default {
  name: 'Ground',
};
</script>

<style lang="scss">
// .ground {
//   position: absolute;
//   background-image: url('/images/ground-start.png');
//   background-size: 1400px;
//   background-position: bottom;
//   background-repeat: repeat;
//   bottom: 0px;
//   right: 0;
//   width: 100%;
//   height: 50px;
// }
// @media screen and (max-width: 1000px) {
//   .ground {
//     background-image: url('/images/ground-footer.png');
//     height: 65px;
//     background-position: top left;
//     background-size: 100px;
//   }
// }
.ground {
  background-image: url('/images/ground-footer.png');
  height: 65px;
  width: 100%;
  background-position: top left;
  background-size: 100px;
}
</style>
