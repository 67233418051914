<template>
  <div class="city-container">
    <div ref="city" class="city" color="white">
      <div class="blur" />
    </div>
    <div ref="city-background" class="city-background" :style="{ zIndex: gameInfo.current_level === 'c_3' || gameInfo.current_level === 'q_3' ? '15' : '21'}"/>
  </div>
</template>

<script>
import { Power0 } from 'gsap';

export default {
  name: 'City',
  props: {
    moving: { type: Boolean, default: false },
  },
  computed: {
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    levelMap() {
      if (this.gameInfo.current_level !== undefined) return this.$store.state.game.gameInfoMap.levels[this.gameInfo.current_level]
      else return this.$store.state.game.gameInfoMap.levels.idle
    },
  },
  watch: {
    levelMap: {
      immediate: true,
      handler(to, from) {
        if (to && from && to.images.city !== from.images.city || to && from === undefined )
        this.$nextTick(async () => {
          this.$tween.set(this.$refs.city, { backgroundPositionX: '2000px', backgroundPosition: 'bottom' });
          this.$tween.to(this.$refs.city, 2, { backgroundPositionX: '0px', backgroundImage: `url(/images/${to.images.city}.png)`, ease: Power0.noneEase });
          this.$tween.to(this.$refs['city-background'], 1, { backgroundPosition: 'bottom left', backgroundImage: `url(/images/${to.images.city}-bg.png)` });
        });
      },
    },
  },
  methods: {
  },
  mounted () {
    this.$log('mounted')
    this.$nextTick(async () => {
      this.$tween.set(this.$refs.city, { backgroundPositionX: '2000px', backgroundPosition: 'bottom left' });
      this.$tween.to(this.$refs.city, 2, { backgroundPositionX: '0px', backgroundImage: `url(/images/${this.levelMap.images.city}.png)`, ease: Power0.noneEase });
      this.$tween.to(this.$refs['city-background'], 1, { backgroundPosition: 'bottom left', backgroundImage: `url(/images/${this.levelMap.images.city}-bg.png)` });
    })
  },
};
</script>

<style lang="scss" scoped>
.city-container {
  width: 100%;
  height: 100%;
  max-height: 520px;
}
.city {
  background-size: 1500px;
  background-position: bottom left;
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 20;
}
.city-background {
  background-size: 1500px;
  background-position: bottom left;
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  max-height: 520px;
  position: absolute;
  z-index: 21;
}

.blur {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 696px;
  background-image: linear-gradient(
    180deg,
    rgba(35, 1, 42, 0) 0%,
    #090812 100%
  );
  z-index: 10;
}
.city-moving {
  animation: animCity 5s infinite linear;
}
@media screen and (max-width: 768px) {
  .city {
    background-size: 900px;
  }
  .city-background {
    background-size: 900px;
  }
  .blur {
    height: 300px;
    background-image: none;
    background-image: linear-gradient(
      3.94deg, rgba(0, 0, 0, 0.7) 3.22%, rgba(255, 255, 255, 0) 174.62%);
  }
}
@keyframes animCity {
  from {
    background-position-x: 0px;
  }
  to {
    background-position-x: -1920px;
  }
}
</style>
