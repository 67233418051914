<template>
  <div ref="dialog" class="dialog" :class="[gameInfo.boss_health === 0 ? 'win-color' : 'defeat-color']">
    <div class="dialog-flex-container">
      <!-- <p> Правильных ответов {{ correct + '/' + all }} </p> -->
    </div>
    <div class="dialog-flex-container">
      <star-rating
        v-model="rating"
        :star-size="starSize"
        :max-rating="3"
        :show-rating="false"
        :read-only="true"
        active-color="#6DEDFC"
        :rounded-corners="true"
        inactive-color="#2e2e2e"
        :padding="3"
      />
    </div>
    <!-- header -->
    <div v-if="heroWon" class="dialog-flex-container">
      <h1>Ты победил!</h1>
    </div>
    <div v-if="!heroWon" class="dialog-flex-container">
      <h1>Ты проиграл!</h1>
    </div>
    <!-- body -->
    <div class="dialog-flex-container">
      <p v-if="heroWon">Ты показал этому злодею, где баги зимуют.</p>
      <p v-if="!heroWon">Ты что, мало каши ел? Собери знания в кулак — ты же IT-специалист!</p>
    </div>
    <!-- action -->
    <div class="dialog-flex-container mt-24">
      <div class="dialog-flex-container">
        <button
          class="geecko-button"
          @click="$emit('finished')"
        >
          {{ gameInfo.current_level === 'c_3' ? 'К результатам' : 'К следующей главе' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating';

export default {
  name: 'DialogBossEnd',
  components: { StarRating },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    starSize() {
      let size;
      if (window.innerWidth < 768) {
        size = 60
      } else {
        size = 90
      }
      return size
    },
    rating() {
      return 3 - this.gameInfo.boss_health
    },
    heroWon () {
      return this.gameInfo.player_health >= this.gameInfo.boss_health
    }
  },
  mounted () {
    this.$log('moutned')
    this.$nextTick(async () => {
      this.$log('moutned $nextTick')
      const dialog = this.$refs.dialog;
      this.$tween.set(dialog, { opacity: '0' });
      this.$tween.to(dialog, 1, { opacity: '1' });
    });
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.geecko-button {
  width: 230px;
}
p {
  color: #e0e0e0;
  max-width: 500px;
  text-align: center;
}
.dialog {
  position: absolute;
  color: #e0e0e0;
  // width: 100%;
  // height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  &-flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    width: 100%;
    padding-top: 20px;
    h1 {
      font-size: 46px;
    }
  }
}
.defeat-color {
  background-image: linear-gradient(
    180deg,
    rgba(35, 1, 42, 0) 0%,
    #6C0808 180%
  );
}
.win-color {
  background-image: linear-gradient(
    180deg,
    rgba(35, 1, 60, 0) -30%,
    #136e3d 180%
  );
}
@media screen and (max-width: 768px) {
  .dialog {
    position: absolute;
    color: #e0e0e0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    &-flex-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-top: 0px;
      padding-bottom: 0px;
      h1 {
        font-size: 34px;
        margin: 0;
        padding: 0;
      }
      p {
        text-align: center;
      }
      .geecko-button {
        width: 180px;
        text-align: center;
        margin: 0;
        padding: 0;
      }
    }
  }
  .defeat-color {
  background-image: linear-gradient(
    -90deg,
    rgba(35, 1, 42, 0) -40%,
    #6C0808 150%
  );
  }
  .win-color {
    background-image: linear-gradient(
      -90deg,
      rgba(35, 1, 42, 0) -100%,
      #136e3d 130%
    );
  }
}
</style>
