<template>
  <div
    class="flex flex-row flex-wrap w-full justify-center"
    :style="{
      position: 'relative',
      overflowX: 'hidden',
    }"
  >
    <!-- preload all images -->
    <div v-for="(f, fkey) in images" :key="fkey">
      <div :style="{position: 'absolute', backgroundImage: `url(/images/${f}.png)`}" />
    </div>

    <!-- SLOT window -->
    <div
      class="flex flex-row flex-wrap w-full justify-center"
      :style="{
        position: 'absolute',
        zIndex: 10000,
        top: '0px',
      }"
    >
      <GameHeader v-if="true" />
    </div>
    <!-- music -->
    <DialogMusic
      v-if="stage === 'dialog_music'"
      :style="{
        position: 'absolute',
        zIndex: 10000,
      }"
      @finished="user.phone ? dialogInfoFinished() : stage = 'dialog_info'"
    />
    <!-- info -->
    <DialogInfo
      v-if="stage === 'dialog_info'"
      :style="{
        position: 'absolute',
        zIndex: 10000,
      }"
      @finished="dialogInfoFinished()"
    />
    <!-- Boss fight ended dialog -->
    <DialogBossEnd
      v-if="bossStats"
      :style="{
        position: 'absolute',
        zIndex: 1000,
        top: '0px',
        width: '100%',
        height: '100%',
      }"
      @finished="(bossStats = false), sberOffer()"
    />
    <!-- ground -->
    <div
      class="flex flex-row flex-wrap w-full justify-center"
      :style="{
        position: 'relative',
        height: $store.state.ui.width > 768 ? '100vh' : '35vh',
      }"
    >
      <Sky v-if="true" />
      <Clouds
        v-if="true"
        :style="{
          position: 'absolute',
          bottom: 0,
          zIndex: 9,
          pointerEvents: 'none',
        }"
        class=""
      />
      <City
        v-if="true"
        :style="{
          position: 'absolute',
          bottom: 0,
          zIndex: 10,
          pointerEvents: 'none',
        }"
        class=""
      />
      <Ground
        v-if="true"
        :style="{
          position: 'absolute',
          bottom: 0,
          zIndex: 100,
          pointerEvents: 'none',
        }"
        class=""
      />
      <!-- Tint for boss questions background, for better visibility -->
      <div ref="tint" v-if="['c_1', 'c_2', 'c_3'].includes(gameInfo.current_level) && $store.state.ui.width > 768" class="blur" />
      <!-- SLOT city -->
      <div
        class="flex flex-row flex-wrap w-full justify-center"
        :style="{
          position: 'relative',
          maxWidth: 1280 + 'px',
          height: '100%',
        }"
      >
        <!-- SLOT view 1280 -->
        <Hero
          v-if="true"
          ref="hero"
          :style="{
            position: 'absolute',
            zIndex: 200,
            bottom: '65px',
            left: '0px',
            pointerEvents: 'none !important',
          }"
        />
        <Boss
          v-if="['c_1', 'c_2', 'c_3'].includes(gameInfo.current_level)"
          ref="boss"
          class=""
          :boss-id="gameInfo.current_level"
          :style="{
            position: 'absolute',
            zIndex: 201,
            bottom: '65px',
            right: '0px',
            pointerEvents: 'none !important',
          }"
        />
        <Npc
          v-if="true"
          ref="npc"
          :style="{
            pointerEvents: 'none !important',
          }"/>
        <!-- body -->
        <div
          v-if="true"
          class="flex flex-row flex-wrap w-full"
          :style="{
            position: 'relative',
            zIndex: 101,
            maxWidth: 700 + 'px',
            backgrounds: 'hsla(120,20%,50%,0.5)',
            marginTop:
            $store.state.ui.width > 768 ? '20vh' : '30vh',
            maxHeight: $store.state.ui.width > 768 ? '300px' : '70vh',
            padding: $store.state.ui.width > 768 ? '0px' : '10px',
            marginBottom: '200px',
            overflow: $store.state.ui.width > 768 ? 'visible' : 'auto',
            paddingTop: '30px'
          }"
        >
          <!-- SLOT dialog -->
          <!-- offer -->
          <DialogOffer
            v-if="offer"
            :style="{
              maxWidth:  $store.state.ui.width > 768 ? '600px' : 'max-content',
            }"
            @finished="nextLevel(), offer = false"
          />
          <!-- game start -->
          <DialogFlow
            v-if="stage === 'dialog_npc'"
            ref="dialog"
            :dialogs="levelCurrent.messagesIntro"
            :style="{
              maxWidth:  $store.state.ui.width > 768 ? '600px' : '70vh',
            }"
            @finished="fightBoss()"
          />
          <!-- end dialog -->
          <DialogFlow
            v-if="stage === 'afterLastBoss'"
            ref="dialog"
            :dialogs="levelCurrent.messageOnWinAfter"
            :style="{
              maxWidth:  $store.state.ui.width > 768 ? '600px' : '70vh',
            }"
            @finished="nextLevel()"
          />
          <!-- Quiz -->
          <!-- <QuizFlow
            v-if="['q_1', 'q_2', 'q_3'].includes(gameInfo.current_level) && stage === 'fight'"
            :refsParent="$refs"
            @finished="nextLevel()"
          /> -->
          <!-- Boss -->
          <QuizBossFlow
            v-if="stage === 'fight' && ['c_1', 'c_2', 'c_3'].includes(gameInfo.current_level)"
            :refsParent="$refs"
            @finished="quizBossFlowFinished"
          />
          <!-- Offer -->
          <!-- Final -->
        </div>
      </div>
    </div>
    <!-- ninja turtles here -->
    <!-- underground -->
    <div
      v-if="$store.state.ui.width <= 768"
      class="flex flex-row flex-wrap w-full"
      :style="{
        position: 'relative',
        zIndex: 1000,
        minHeight: '65vh',
        background: '19192F',
        pointerEvents: 'none',
        marginBottoms: '0px',
        overflow: 'auto',
      }"
    >
      <!-- SLOT underground -->
    </div>
  </div>
</template>

<script>
import DialogMusic from "@/components/Game/DialogMusic.vue";
import DialogInfo from "@/components/Game/DialogInfo.vue";
import DialogFlow from "@/components/Game/DialogFlow.vue";
import DialogBossEnd from "@/components/Game/DialogBossEnd.vue";
import DialogOffer from "@/components/Game/DialogOffer.vue";
import QuizBossFlow from "@/components/Game/QuizBossFlow.vue";
// Environment
import GameHeader from "@/components/Game/GameHeader.vue";
import Sky from "@/components/Game/Sky.vue";
import Ground from "@/components/Game/Ground.vue";
import City from "@/components/Game/City.vue";
import Clouds from "@/components/Game/Clouds.vue";
import Hero from "@/components/Game/Hero.vue";
import Boss from "@/components/Game/Boss.vue";
import Npc from "@/components/Game/Npc.vue";

export default {
  name: "GameLayout",
  components: {
    DialogMusic,
    DialogInfo,
    DialogFlow,
    DialogBossEnd,
    DialogOffer,
    QuizBossFlow,
    GameHeader,
    Sky,
    Ground,
    City,
    Clouds,
    Hero,
    Boss,
    Npc,
  },
  data() {
    return {
      stage: "dialog_music", // dialog_music, dialog_info, dialog_npc(DialogFlow) fight(QuizBossFlow), DialogBossEnd(offer, ), afterLastBoss(DialogFlow)
      images: ['sky-first', 'sky-second', 'sky-third', 'city-first', 'city-second', 'city-third', 'city-first-bg', 'city-second-bg', 'city-third-bg'],
      bossStats: false,
      offer: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    gameInfo() {
      return this.$store.state.game.gameInfo
    },
    gameInfoMap () {
      return this.$store.state.game.gameInfoMap
    },
    levelKey () {
      return this.gameInfo.current_level
    },
    levelCurrent() {
      return this.gameInfoMap.levels[this.levelKey]
    },
  },
  watch: {
    "$store.state.ui.soundOn": {
      immediate: true,
      handler(to) {
        this.$log("$store.state.ui.soundOn TO", to);
        if (!this.sound)
          this.sound = this.$sound(
            "/images/game/audio/theme_1.mp3",
            false,
            0.2
          );
        if (to) {
          if (this.sound) this.sound.play();
        } else {
          if (this.sound) this.sound.pause();
        }
      },
    },
    "$store.state.game.gameInfo.current_level": {
      // deep: true,
      immediate: true,
      async handler(to) {
        this.$log('*** W currentLevel', to)
        // Control animations
        // this.$nextTick(() => {
        //   this.tintDessappear()
        //   // TODO: this.$tween.to(this.$refs.tint, 1, { opacity: '1' })
        // })

        // Control idle stage
        // if (to === 'idle') {
        //   this.$log('*** W currentLevel IDLE')
        //   // check user.phone
        //   // if (this.user.phone) this.stage = 'idle_music'
        //   // else this.stage = 'idle_phone'
        //   this.stage = 'dialog_npc'
        // }

        // Control quis stages
        // if (['q_1', 'q_2', 'q_3'].includes(to)) {
        //   // do stuff
        //   // if (this.gameInfo.question_serial === 1)
        //   this.stage = 'fight'
        // }

        // Control boss stages
        if (['c_1', 'c_2', 'c_3'].includes(to)) {
          this.$log('*** W currentLevel c_1,c_2,c_3', to)
          // Make stage idle to show intro dialogs, only when hitpoints === 3, for player and boss
          if (this.gameInfo.boss_health === 3 && this.gameInfo.player_health === 3) {
            this.$log('*** W currentLevel c_1,c_2,c_3 3vs3')
            this.stage = 'dialog_npc'
          }
          // They are damaged somehow
          else {
            this.$log('*** W currentLevel c_1,c_2,c_3 DAMAGED')
            // If someone is dead go to the next level...
            if (this.gameInfo.boss_health === 0 || this.gameInfo.player_health === 0) {
              this.nextLevel()
            }
            // If not? Fight!
            else {
              this.stage = 'fight'
            }
          }
          this.$nextTick(async () => {
            // boss appearance
            await this.$wait(100)
            this.$refs.boss.animAppearance()
          })
        }
        // Control final
        if (to === 'final') {
          this.$log('*** W currentLevel FINAL')
          // do stuff
          this.$router.replace('/final').catch(e => e)
        }
      }
    },
  },
  methods: {
    tintDessappear () {
      this.$log('tintDessappear')
      this.$tween.to(this.$refs.tint, 1, { opacity: '0' })
    },
    tintApear () {
      this.$log('tintApear')
      this.$tween.to(this.$refs.tint, 1, { opacity: '1' })
    },
    quizBossFlowFinished () {
      this.$log("quizBossFlowFinished");
      this.bossStats = true
    },
    sberOffer () {
      this.$log('sberOffer')
      // If the first boss?
      if (this.levelKey === 'c_1') {
        this.$log('sberOffer After first boss')
        // And you killed him
        // this.offer = true
        this.nextLevel()
      }
      else {
        this.$log('sberOffer nextLevel')
        this.nextLevel()
      }
    },
    async nextLevel () {
      this.$log("nextLevel", this.gameInfo.current_level);
      const levelCurrent = this.gameInfo.current_level;
      // Get next level
      const {
        levels: {
          [levelCurrent]: { levelNext },
        },
      } = this.$store.state.game.gameInfoMap;
      this.$log("levelNext", levelNext);
      // animations
      if (['c_1', 'c_2', 'c_3'].includes(this.gameInfo.current_level)) {
        if (this.gameInfo.player_health === 3) this.$refs.hero.animRessurrect()
        await this.$wait(1000)
        this.$refs.hero.animRunOut()
        await this.$wait(2900)
      }
      // change level
      const { data: levelChanged } = await this.$http.post(
        "/game/change-level",
        { level: levelNext }
      )
      this.$log("levelChanged", levelChanged)
      // Update gameState
      await this.$store.dispatch("game/fetchGame")
      // Handle final stage
      if (this.gameInfo.current_level === 'final') {
        this.$router.replace('/final').catch(() => {})
      }
    },
    async fightBoss() {
      this.$log('fightBoss start')
      this.stage = 'fight'
      this.tintApear()
      this.$nextTick(async () => {
        this.$log('fightBoss $nextTick')
        // this.$refs.boss.say(this.levelCurrent.messages[0].message, 6000);
        // await this.$wait(5000)
        // this.$refs.npc.animAppear(this.levelCurrent.messages[1].message, 4000, 'talk');
        // await this.$wait(4000)
        this.$log('fightBoss $nextTick DONE')
      })
    },
    async dialogInfoFinished () {
      this.$log('dialogInfoFinished start')
      await this.$store.dispatch("game/startGame")
      await this.$http.post("/game/change-level", { level: 'c_1' })
      await this.$store.dispatch("game/fetchGame")
      this.stage = 'dialog_npc'
      this.$log('dialogInfoFinished DONE')
    }
  },
  async mounted () {
    this.$log("mounted")
    this.$nextTick(() => {
      this.$log('mounted set tint 0')
      // this.$tween.set(this.$refs.tint, { opacity: '0' })
    })
  },
  beforeDestroy() {
    this.$log("beforeDestroy");
  },
};
</script>

<style lang="scss">
.blur {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    -90deg,
    rgba(35, 1, 42, 0) 0%,
    #070415 100%
  );
  z-index: 10;
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  body {
    background: #191930;
  }
}
</style>
