<template>
  <div ref="boss" :class="boss.id" :style="{width: width + 'px', height: height + 'px'}">
    <!-- DOWNLOAD IMAGES -->
    <div v-for="im in images" :key="im.id">
      <div v-for="bs in bosses" :key="bs.id">
        <div :style="{position: 'absolute', zIndex: '-1', backgroundImage: `url(/images/game/boss/${bs}/${im}.png)`}" />
      </div>
    </div>
    <!-- SAY -->
    <div
      ref="bubble"
      class="bubble"
      :style="{
        marginBottom: gameInfo.current_level ===  'c_3' ? $store.state.ui.width > 768 ? '100px' : '-50px' : '0px'
      }">
      <p>{{ msg }}</p>
    </div>
  </div>
</template>

<script>
import { SteppedEase } from 'gsap';

export default {
  name: 'Boss',
  props: {
    bossId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      timeline: null,
      idle: null,
      el: null,
      msg: null,
      bosses: ['boss-1', 'boss-2', 'boss-3'],
      images: ['death', 'attack', 'damaged', 'idle', 'appearance', 'appearance'],
      attack: null,
      pain: null,
    };
  },
  computed: {
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    levelMap() {
      if (this.gameInfo.current_level !== undefined) return this.$store.state.game.gameInfoMap.levels[this.gameInfo.current_level]
      else return this.$store.state.game.gameInfoMap.levels.idle
    },
    health() {
      return this.levelMap?.bossHealth;
    },
    boss() {
      let style = '';
      // ATTACK
      let attackIMG = '';
      // DEATH
      let deathIMG = '';
      // APPEARANCE
      let appearanceIMG = '';
      // IDLE
      let idleIMG = '';
      // DAMAGED
      let damagedIMG = '';
      switch (this.bossId) {
        case 'c_1':
          style = 'boss-1';
          // ATTACK
          attackIMG = '26';
          // DEATH
          deathIMG = '17';
          // APPEARANCE
          appearanceIMG = '12';
          // IDLE
          idleIMG = '10';
          // DAMAGED
          damagedIMG = '7';
          break;
        case 'c_2':
          style = 'boss-2';
          // ATTACK
          attackIMG = '36';
          // DEATH
          deathIMG = '22';
          // APPEARANCE
          appearanceIMG = '16';
          // IDLE
          idleIMG = '12';
          // DAMAGED
          damagedIMG = '3';
          break;
        case 'c_3':
          style = 'boss-3';
          // ATTACK
          attackIMG = '31';
          // DEATH
          deathIMG = '21';
          // APPEARANCE
          appearanceIMG = '20';
          // IDLE
          idleIMG = '19';
          // DAMAGED
          damagedIMG = '4';
          break;
        default:
          break;
      }
      return { id: style, idle: idleIMG, dmg: damagedIMG, atk: attackIMG, death: deathIMG, appearance: appearanceIMG }
    },
    width() {
      let width
      switch (this.bossId) {
        case 'c_1':
          if (window.innerWidth <= 768 && this.bossId === 'c_1') {
            width = '200'
          } else {
            width = '400'
          }
          break
        case 'c_2':
          if (window.innerWidth <= 768 && this.bossId === 'c_2') {
            width = '230'
          } else {
            width = '500'
          }
          break
        case 'c_3':
          if (window.innerWidth <= 768 && this.bossId === 'c_3') {
            width = '150'
          } else {
            width = '400'
          }
          break
        default:
          break;
      }
      return width
    },
    height() {
      let height
      switch (this.bossId) {
        case 'c_1':
          if (window.innerWidth <= 768 && this.bossId === 'c_1') {
            height = '200'
          } else {
            height = '400'
          }
          break
        case 'c_2':
          if (window.innerWidth <= 768 && this.bossId === 'c_2') {
            height = '230'
          } else {
            height = '500'
          }
          break
        case 'c_3':
          if (window.innerWidth <= 768 && this.bossId === 'c_3') {
            height = '150'
          } else {
            height = '400'
          }
          break
        default:
          break;
      }
      return height
    },
  },
  watch: {
    levelMap: {
      immediate: true,
      handler () {
        this.$log('levelMap TO')
      }
    }
  },
  // IDLE
  async mounted () {
    this.$log('mounted')
    // console.log('WORK', this.boss.id, this.width)
    this.$nextTick(() => {
      this.el = this.$refs.boss
      this.$tween.set(this.$refs.bubble, { transform: 'scale(0)' })
      // this.animIdle()
      // this.idle = new this.$timeline()
    })
  },
  methods: {
    async say(msg, time) {
      this.$log('say', msg);
      const bubble = this.$refs.bubble;
      this.$tween.set(bubble, { transform: 'scale(0)' });
      // this.msg = msg;
      this.msg = msg.replace('%username%', this.$store.getters.user.name)
      this.$tween.to(bubble, 0.5, { transform: 'scale(1)' });
      await this.$wait(time);
      this.$tween.to(bubble, 0.5, { transform: 'scale(0)' });
    },
    soundAttack() {
      this.$sound('/images/game/audio/boss-attack.mp3')
    },
    soundDamaged() {
      this.$sound('/images/game/audio/boss-pain.mp3')
    },
    animIdle () {
      this.$log('animIdle')
      if (this.timeline) this.timeline.kill();
      this.timeline = new this.$timeline();
      this.timeline.set(this.el, { backgroundImage: `url(/images/game/boss/${this.boss.id}/idle.png`, backgroundSize: `${this.width * this.boss.idle}px`, backgroundPosition: 'left bottom' });
      this.timeline.to(this.el, 7, { repeat: -1, backgroundPositionX: `-${this.width * this.boss.idle}px`, ease: SteppedEase.config(this.boss.idle) });
    },
    // DEATH
    async animDeath () {
      this.$log('animDeath')
      if (this.timeline) this.timeline.kill();
      this.timeline = new this.$timeline();
      this.timeline.set(this.el, {
        backgroundImage: `url(/images/game/boss/${this.boss.id}/death.png`,
        backgroundSize: `${this.boss.death * this.width}px`,
        backgroundPosition: 'left bottom',
        // backgroundPositionX: '9000px',
      });
      this.timeline.to(this.el, 10, { repeat: 0, backgroundPositionX: `-${this.boss.death * this.width}px`, ease: SteppedEase.config(this.boss.death) });
      await this.$wait(900);
      this.timeline.kill();
      this.$tween.set(this.el, { backgroundImage: '' });
      this.timeline.kill();
    },
    // RESS
    async animAppearance () {
      this.$log('animAppearance')
      if (this.timeline) this.timeline.kill();
      this.timeline = new this.$timeline({ repeat: 0 });
      this.timeline.set(this.el, {
        backgroundImage: `url(/images/game/boss/${this.boss.id}/appearance.png`,
        backgroundSize: `${this.boss.appearance * this.width}px`,
        backgroundPosition: 'left bottom',
      });
      this.timeline.to(this.el, 10, { repeat: 0, backgroundPositionX: `-${this.boss.appearance * this.width}px`, ease: SteppedEase.config(this.boss.appearance) });
      await this.$wait(900);
      this.timeline.kill();
      this.animIdle();
    },
    // ATTACK
    async animAttack () {
      this.$log('animAttack')
      if (this.timeline) this.timeline.kill();
      this.soundAttack();
      this.timeline = new this.$timeline({ repeat: 0 });
      this.timeline.set(this.el, {
        backgroundImage: `url(/images/game/boss/${this.boss.id}/attack.png`,
        backgroundSize: `${this.boss.atk * this.width}px`,
        backgroundPosition: 'left bottom',
      });
      this.timeline.to(this.el, 10, { repeat: 0, backgroundPositionX: `-${this.boss.atk * this.width}px`, ease: SteppedEase.config(this.boss.atk) });
      await this.$wait(1100);
      this.timeline.kill();
      this.animIdle();
    },
    // DAMAGED
    async animDamaged() {
      this.$log('animDamaged')
      if (this.timeline) this.timeline.kill();
      this.soundDamaged();
      this.timeline = new this.$timeline();
      this.timeline.set(this.el, {
        backgroundImage: `url(/images/game/boss/${this.boss.id}/damaged.png`,
        backgroundSize: `${this.boss.dmg * this.width}px`,
        backgroundPosition: 'left bottom',
      });
      this.timeline.to(this.el, 2, { repeat: 0, backgroundPositionX: `-${this.boss.dmg * this.width}px`, ease: SteppedEase.config(this.boss.dmg) });
      await this.$wait(1000);
      this.timeline.kill();
      this.animIdle();
    },
  },
};
</script>

<style lang="scss" scoped>
.boss-1 {
  display: flex;
  // background-repeat: repeat;
  background-position: left bottom;
  // background-size: 200px;
  color: white;
}

.boss-2 {
  display: flex;
  // width: 600px;
  // height: 600px;
  // background-repeat: repeat;
  background-position: left bottom;
  // background-size: 300px;
  color: white;
  // border: 1px solid red;
}
.boss-3 {
  display: flex;
  // width: 500px;
  // height: 80%;
  // background-repeat: repeat;
  // background-position: left bottom;
  // background-size: 300px;
  color: white;
  // border: 1px solid red;
}
.bubble {
  position: absolute;
  max-width: 300px;
  width: 100%;
  max-height: 400px;
  background-color: #fff;
  padding: 10px;
  font-size: 1.25em;
  border-radius: 1rem;
  z-index: 1000000;
  // border: 1px solid red;
  bottom: 30%;
  right: 70%;
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-left-color: #fff;
    border-right: 0;
    margin-top: -10px;
    margin-right: -9px;
  }
}
@media screen and (max-width: 768px) {
  .bubble {
    position: absolute;
    max-width: 200px;
    width: 100%;
    max-height: 300px;
    background-color: #fff;
    padding: 10px;
    border-radius: 1rem;
    z-index: 500 !important;
    // border: 1px solid red;
    bottom: 30%;
    right: 70%;
    p {
      font-size: 14px;
      line-height: 14px;
    }
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 10px;
      width: 0;
      height: 0;
      border: 0px solid transparent;
      border-left-color: #fff;
      border-right: 0;
      margin-right: 5px;
    }
  }
}
</style>
