<template>
  <Dialog
    v-if="dialog"
    ref="dialog"
    :header-text="getAuthorName(dialog.author)"
    :header-image="getAuthorImage(dialog.author)"
    :handle-click="dialogNext"
    :button-text="dialog.confirmText"
  >
    <!-- dialog default slot -->
    <div
      class="flex flex-row flex-wrap w-full text-container"
    >
      <h3
        v-if="dialog.title"
        :style="{
          color: 'white',
          fontSize: '30px',
          fontWeight: 'bold',
        }"
      >
        {{ dialog.title }}
      </h3>
      <p
        class="dialog-text mb-4"
      >
        {{ getMessage(dialog.message) }}
      </p>
    </div>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Game/Dialog.vue';

export default {
  name: 'DialogFlow',
  components: {
    Dialog,
  },
  props: {
    dialogs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: null,
      dialogIndex: 0,
    };
  },
  computed: {
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
  },
  watch: {
    dialogs: {
      immediate: true,
      handler(to) {
        if (to) {
          this.$log('W dialogs', to);
          this.dialog = to[this.dialogIndex];
        }
      },
    },
  },
  methods: {
    hide () {
      this.$log('hide')
      this.$tween.to(this.$refs.dialog.$el, 1, { opacity: '0' });
    },
    dialogNext() {
      this.$log('dialogNext');
      if (this.dialogIndex === this.dialogs.length - 1) {
        // this.dialogIndex = 0
        this.$log('dialogNext finished');
        this.$emit('finished');
      }
      else {
        this.$log('dialogNext next');
        this.dialogIndex += 1;
        this.dialog = this.dialogs[this.dialogIndex];
      }
    },
    // functional getters
    getMessage(msg) {
      // TODO: hack with only %username%, use regexp instead...
      return msg.replace('%username%', this.$store.getters.user.name);
    },
    getAuthorName(authorId) {
      const nameMap = {
        npc: 'Старейшина',
        c_1: 'Рынкус',
        c_2: 'Регресус',
        c_3: 'Пирамидус',
      };
      return nameMap[authorId];
    },
    getAuthorImage(authorId) {
      const imgMap = {
        npc: '/images/teamleadus.png',
        c_1: '/images/boss-1.png',
        c_2: '/images/boss-2.png',
        c_3: '/images/boss-3.png',
      };
      return imgMap[authorId];
    },
  },
  mounted() {
    this.$log('mounted dialogIndex', this.dialogIndex)
  },
  beforeDestroy () {
    this.$log('beforeDestroy')
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 768px) {
  p {
    font-size: 18px;
    line-height: 20px;
  }
  .text-container {
    overflow: auto;
    max-height: calc(70vh-48px) !important;
  }
}
</style>
